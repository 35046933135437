import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../../../components/Layout';
import SEO from '../../../../components/seo';
import Content from '../../../../components/Content';
import Block from '../../../../components/Block';
import Hero from '../../../../components/Hero';
import Anchors from '../../../../components/Anchors';
import Subnavigation from '../../../../components/Subnavigation';
import PersonBadge from '../../../../components/PersonBadge';
import Breadcrumb from '../../../../components/Breadcrumb';

const GISSoftware = ({pageContext}) => (
	<Layout>
		<SEO title={pageContext.service.title['en']} />
		<Hero>
			<Breadcrumb crumbs={pageContext?.crumbs} />
			<h1>{pageContext.service.title['en']}</h1>
		</Hero>
		<Block light>
			<Content>
				<Subnavigation>
					<Anchors>
						<a href={'#catalyst'}>Catalyst</a>
						<a href={'#ecognition'}>eCognition</a>
					</Anchors>

					<PersonBadge
						note={pageContext.service.contactNote}
						email={pageContext.service.contactPerson}
					/>
				</Subnavigation>
			</Content>
		</Block>
		<Block light>
			<Content>
				<p>
					GISAT is a long term partner of two major geomatics software companies
					providing a wide range of software tools and applications to process
					and analyze satellite imagery. We provide sales services and customer
					support for the region of Czechia and Slovakia.
				</p>
			</Content>
		</Block>
		<Block light>
			<Content>
				<h2 id={'catalyst'}>Catalyst</h2>
				<p>
					<a href={'https://catalyst.earth/'}>CATALYST</a> is a PCI Geomatics
					brand that delivers scalable business solutions using the power of
					Earth Observation data and analytics.
				</p>
				<p>
					PCI Geomatics is a Canadian software development company that creates
					and delivers geo-image software tools, platforms and solutions for
					over 30 years. It develops desktop and enterprise software products
					that allow its customers to produce information from a myriad of
					aerial and satellite earth observing platforms.
				</p>
				<p>
					CATALYST is a powerful and modular platform that provides complete and
					integrated software featuring the tools professionals need for remote
					sensing, digital photogrammetry, image analysis, map production,
					mosaicking and more. It includes a number of modules to process,
					analyze and comprehend aerial, UAV, optical and radar satellite
					imagery.
				</p>
			</Content>
		</Block>
		<Block light>
			<Content>
				<h2 id={'ecognition'}>eCognition</h2>
				<p>
					<a
						href={
							'https://geospatial.trimble.com/products-and-solutions/trimble-ecognition'
						}
					>
						Trimble eCognition
					</a>{' '}
					represents an ultimate image classification and feature extraction
					solution to transform geo-data into geo-information taking advantage
					of both pixel-based and object-based image analysis techniques (OBIA).
					It enables to accelerate and automate the interpretation of the
					geospatial data products by allowing to design own feature extraction
					and change detection solutions and provide the flexibility to combine
					the image interpretation steps like object creation based on
					segmentation, knowledge-based object classification or machine
					learning. User can leverage the full power of their input data
					independent of data type and source.
				</p>
			</Content>
		</Block>
	</Layout>
);

GISSoftware.propTypes = {
	pageContext: PropTypes.object.isRequired,
};
export default GISSoftware;
